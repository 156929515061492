button.fab{
    position: fixed;
    bottom: 10px;
    right: 10px;
}
.haut{
    height:100px;
}

/*.Conteneur_Champ .MuiContainer-root{
    width:50%;
}*/

.Conteneur_Champ .MuiFormControl-root{
    width: 100%;
}

button.greenButton{
    background-color: green;
}
button.greenButton:hover{
    background-color: rgb(0, 110, 0);
}


.Conteneur_Champ .blocChampsExtra .MuiFormControl-root{
    width: 200px;
}

.flex{
    display:flex !important;
}

.MuiContainer-root.blocChampsExtra{
    margin-bottom: 20px;
    margin-top: 20px;
}

.toDelete{
    background-color: red !important;
}