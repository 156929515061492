h3{
  display: table;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 0;
}

h3:before, h3:after {
  content: "";
  display: table-cell;
  width: 50%;
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=) repeat-x left center;
}

.Container_Center{
  display:table !important;
  /*width:0 !important;*/
}

.Container_Center > div{
  display:flex;
}

.width150 > div{
  width : 150px;
  margin: 0 5px 0 5px;
}
.width300 > div{
  width : 300px;
  margin: 0 5px 0 5px;
}
.width600 > div{
  width : 600px;
  margin: 0 5px 0 5px;
}

.Table_Ingredients{
  width:100%;
  border-collapse:collapse;
}
.Table_Ingredients th{
  width:33%;
}

.Table_Ingredients .MuiFormControl-root{
  width:100%;
}

.Table_Ingredients tbody td{
  border-top:1px dotted grey;
  padding: 15px 0px;
}

.MuiContainer-root.Action_Recette{
  width:300px;
  margin-top: 10px;
}

.Action_Recette .MuiFormControl-root{
  width: 100%;
}